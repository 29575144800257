import React, { useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Skeleton } from '@mui/material';
import Agences from "../Database/Agences.json";
import CoordonneMap from '../Models/CoordonneMap';
import Agence from '../Models/Agence';
import env from 'react-dotenv';

const containerStyle = {
    width: '100%',
    height: '400px'
};


function Map(props: {center : CoordonneMap}) {

    const center : CoordonneMap = props.center

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: env.GOOGLE_MAPS_API_KEY
    })
    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (marker: any) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const markers : Array<Agence> = Agences

    const [map, setMap] = React.useState(null)
    const [show, setShow] = React.useState(false)

    const onLoad = React.useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <div style={{ border: "2px #0D6638 solid", borderRadius: "1%", overflow: "auto"}}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
                // options={ {maxZoom:12} }
            >
                {markers.map((agence) => (
                    <Marker
                        title={agence.numero + " - " + agence.agence + " (" + agence.emplacement + ")"}
                        key={agence.numero}
                        position={agence.coordonnees}
                        onClick={() => handleActiveMarker(agence.numero)}
                        onMouseOver={()=>{setShow(true)}}
                        onMouseOut={()=>{setShow(false)}}
                    >
                    </Marker>
                ))}
            </GoogleMap>
        </div>
    ) : <Skeleton variant='rounded' width={"100%"} height={400} />
}

export default Map