import { Carousel } from 'antd'
import { Variants, motion } from 'framer-motion'
import React from 'react'
import ProduitOuService from './ProduitOuService'
import TexteProduitEtServices from '../Fichiers/text_nosProduitsEtServices.svg'
import { ArrowBack, ArrowLeft, ArrowRight, NextPlan } from '@mui/icons-material'
import { Box, Container, Typography } from '@mui/material'
import ProduitEtServiceList from "../Database/ProduitEtServiceList.json";
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import illution_4 from "../Fichiers/illution_4.svg";

function NosProduitsEtServoces() {
    const { t, i18n } = useTranslation();


    const settings = {
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }
    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <Container maxWidth="lg">
                    <Box textAlign={"justify"} >
                        <h1>{t("TITRE.NosProduitsEtServices")}</h1>
                        <img width={"100%"} draggable={false} src={illution_4} alt="" />
                        <br /><br />
                        <label className='textgray' htmlFor="">{t("SOUS_TITRE.NosProduitsEtServices")}</label>
                    </Box>

                    <br /><br />
                </Container>
            </motion.div>
            <br />
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <center>
                    <Box display={{ xs: "none", sm: "block" }}>
                        <Carousel slidesToShow={ProduitEtServiceList.length >= 4 ? 4: ProduitEtServiceList.length} dots={false} arrows {...settings} autoplay dotPosition='bottom'>
                            {ProduitEtServiceList.map((p) => (
                                (p.pub == false) ? <ProduitOuService produitService={p} /> : ""
                            ))}
                        </Carousel>
                    </Box>
                    <Box display={{ xs: "block", sm: "none" }}>

                        <Carousel slidesToShow={1} dots={false} arrows {...settings} autoplay dotPosition='bottom'>
                            {ProduitEtServiceList.map((p) => (
                                (p.pub == false) ? <ProduitOuService produitService={p} /> : ""
                            ))}
                        </Carousel>
                    </Box>
                </center>
            </motion.div>
        </div >
    )
}

export default NosProduitsEtServoces
