import { Facebook, LinkedIn, Mail, Twitter, YouTube } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props { }

function ReseauxSociaux(props: Props) {
    const { } = props

    return (
        <>
            <Link to="https://www.linkedin.com/company/mupeci-1" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='info'><LinkedIn /></IconButton></Link>
            <Link to="https://www.facebook.com/profile.php?id=100077315929639" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='info'><Facebook /></IconButton></Link>
            <Link to="https://x.com/MUPECI_" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='info'><Twitter /></IconButton></Link>
            <Link to="mailto:contact@mupeci.cm" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='error'><Mail /></IconButton></Link>
            <Link to="https://www.youtube.com/@Mupeci" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='error'><YouTube /></IconButton></Link>
        </>
    )
}

export default ReseauxSociaux
