import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from '@mui/material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ResponsiveAppBar from './Components/ResponsiveAppBar';
import Home from './Pages/Home';
import { Routes, Route } from 'react-router-dom';
import About from './Pages/About';
import Uses from './Pages/Uses';
import Projects from './Pages/Projects';
import GetCookie from './Services/GetCookie';
import Data from './Data/.json';
import EmploieEtCarriere from './Pages/EmploieEtCarriere';
import { useScroll, useSpring, motion } from 'framer-motion';
import Postule from './Pages/Postule';
import Contact from './Pages/Contact';
import MotDG from './Pages/MotDG';
import Historique from './Pages/Historique';
import MissionEtVision from './Pages/MissionEtVision';
import Gouvernance from './Pages/Gouvernance';
import Statut from './Pages/Statut';
import Agences from './Pages/Agences';
import NousRejoindre from './Components/NousRejoindre';
import CompteParticulier from './Pages/Comptes/CompteParticulier';
import CompteEntreprise from './Pages/Comptes/CompteEntreprise';
import CompteAssociation from './Pages/Comptes/CompteAssociation';
import Reclamations from './Pages/Reclamations';
import NotFound from './Pages/NotFound';
import CollecteJournaliere from './Pages/Epargnes/CollecteJournaliere';
import EpargnePrevoyance from './Pages/Epargnes/EpargnePrevoyance';
import EprgneSurLivret from './Pages/Epargnes/EprgneSurLivret';
import PretPourParticuliers from './Pages/Prets/PretPourParticuliers';
import Categorisation from './Pages/Prets/Categorisation';
import CreditBail from './Pages/Prets/CreditBail';
import PretImobilier from './Pages/Prets/PretImobilier';
import PretEntreprise from './Pages/Prets/PretEntreprise';
import { Analytics } from "@vercel/analytics/react";
import PolitiqueDeConfidentialite from './Pages/PolitiqueDeConfidentialite';
import TermesEtConditions from './Pages/TermesEtConditions';
import FondsPropres from './Pages/FondsPropres';
import EpargneIslamique from './Pages/EpargneIslamique';
import DevenirMembre from './Pages/DevenirMembre';

function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });


  return (
    <>

      <div className="App" style={{ backgroundColor: GetCookie(Data.mode) == 'light' ? "white" : GetCookie(Data.mode) == 'dark' ? '#05132f' : "", color: GetCookie(Data.mode) == 'light' ? "#333" : GetCookie(Data.mode) == 'dark' ? 'rgba(255, 255, 255, 1)' : "" }}>
        <motion.div className="progress-bar" style={{ scaleX }} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Emplois & carrière' element={<EmploieEtCarriere />} />
          <Route path='/Postule' element={<Postule />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/MotDG' element={<MotDG />} />
          <Route path='/Historique' element={<Historique />} />
          <Route path='/MissionEtVision' element={<MissionEtVision />} />
          <Route path='/Gouvernance' element={<Gouvernance />} />
          <Route path='/Statut' element={<Statut />} />
          <Route path='/Agences' element={<Agences />} />
          <Route path='/CandidatureSpontanee' element={<Postule />} />
          <Route path='/NousRejoindre' element={<EmploieEtCarriere />} />
          <Route path='/comptes/particuliers' element={<CompteParticulier />} />
          <Route path='/comptes/entreprise' element={<CompteEntreprise />} />
          <Route path='/comptes/association' element={<CompteAssociation />} />
          <Route path='/comptes' element={<CompteParticulier />} />
          <Route path='/Reclamations' element={<Reclamations />} />
          <Route path='/epargne/collecte' element={<CollecteJournaliere />} />
          <Route path='/epargne/prevoyance' element={<EpargnePrevoyance />} />
          <Route path='/epargne/surLivret' element={<EprgneSurLivret />} />
          <Route path='/pret/particulier' element={<PretPourParticuliers />} />
          <Route path='/pret/bail' element={<CreditBail />} />
          <Route path='/pret/immobiliers' element={<PretImobilier />} />
          <Route path='/pret/Entreprises' element={<PretEntreprise />} />
          <Route path='/pret/categorisation' element={<Categorisation />} />
          <Route path='/politiqueDeconfidentialite' element={<PolitiqueDeConfidentialite />} />
          <Route path='/termesEtConditions' element={<TermesEtConditions />} />
          <Route path='/FondsPropres' element={<FondsPropres />} />
          <Route path='/epargne/islamique' element={<EpargneIslamique />} />
          <Route path='/DevenirMembre' element={<DevenirMembre />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      <Analytics />
    </>
  );
}

export default App;
