import React, { Suspense } from 'react';
import './i18n'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Load from './Pages/Load';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(    
<BrowserRouter>
  <Suspense fallback={<Load />}>
      <App />
  </Suspense>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();