import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import OpenActualiteOuEvement from './OpenActualiteOuEvement';
import Post from '../Models/Post';
import data from '../Data/.json'
import i18next from 'i18next';


function ActualiteOuEvenement(props: { post: Post }) {

    return (
        <Box>
            <Card sx={{ maxWidth: "90%" }} className='produitCard'>
                <CardMedia
                    sx={{ height: 100 }}
                    image={props.post.image}
                />
                <CardContent>
                    <Typography style={{ fontSize: "100%" }} gutterBottom variant="h5" component="div">
                        {i18next.language == 'en'? props.post.TitrePost.split(data.DATA_SEPARATOR_FOR_MAPING)[1] : props.post.TitrePost.split(data.DATA_SEPARATOR_FOR_MAPING)[0]}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.post.dateEnv}
                    </Typography>
                </CardContent>
                <CardActions style={{ backgroundColor: "#0D6638" }}>
                    <OpenActualiteOuEvement post={props.post} />
                </CardActions>
            </Card>
        </Box>
    )
}

export default ActualiteOuEvenement
