import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import { Box, Container } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import illution_4 from "../Fichiers/illution_4.svg";
import { Carousel } from 'antd';
import StepperAgence from '../Components/StepperAgence';
import StepperTempsFort from '../Components/StepperTempsFort';
import Files from "../Database/Files.json";

interface Props { }

function Historique(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={t("PRODUITS_SERVICES.titre4")} lien="Label.Titre.titre18" />
            <br /><br /><br /><br /><br /><br /><br /><br />



            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>

                    <Box display={{ xs: 'block', sm: 'flex' }} >
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center><br /><br />
                                        <Box border={2} width={300} height={300} borderRadius={2} borderColor={"#0D6638"} padding={1}>
                                            <Carousel style={{ width: "100%" }} dots={true} autoplay >
                                                <div><img width={300} height={300} src={"https://leconomie.info/wp-content/uploads/2023/07/mupeci-1.jpg"} alt="" /></div>
                                                <div><img width={300} height={300} src={"https://i0.wp.com/ripostescm.net/wp-content/uploads/2023/07/IMG-20230718-WA0009.jpg?fit=1080809&ssl=1"} alt="" /></div>
                                                <div><img width={300} height={300} src={"https://camerounexpress.net/wp-content/uploads/2023/07/Screenshot_20230722_080211_Collage-Maker-GridArt.jpg"} alt="" /></div>
                                            </Carousel>
                                        </Box>
                                    </center>
                                </motion.div>
                            </Box>
                        </motion.div>
                        <Box margin={"2%"} textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <img width={"80%"} src={illution_4} alt="" /><br />

                            </motion.div>
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("HISTORIQUE.texte.partie1")}</label>
                            <br /><br />
                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie2")}
                            </label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie3")}
                            </label>
                            <br /><br />

                        </Box>
                    </Box>
                    <br />
                    <img width={"80%"} src={illution_4} alt="" /><br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">
                            {t("HISTORIQUE.texte.partie4")}
                        </label>
                    </motion.div>
                    <br /><br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                            {t("HISTORIQUE.texte.partie5")}
                        </label>
                    </motion.div>
                    <br /><br />


                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box width={"100%"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">
                                    {t("HISTORIQUE.texte.partie6")}
                                </label>
                            </motion.div>
                            <br /><br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                    {t("HISTORIQUE.texte.partie7")}
                                </label>
                            </motion.div>
                            <br /><br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                    {t("HISTORIQUE.texte.partie8")}
                                </label>
                            </motion.div>
                            <br /><br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                    {t("HISTORIQUE.texte.partie9")}
                                </label>
                            </motion.div>
                            <br /><br />
                        </Box>
                        <Box width={100} />
                        <embed style={{ borderRadius: "2%", boxShadow: "1px 1px 20px rgba(0, 0, 0, .1)" }} height={600} width={"100%"} src={require("../Fichiers/Photo_agrément.pdf")} type="application/pdf" />
                    </Box>
                    <Box>
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie10")}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                            {t("HISTORIQUE.texte.partie11")}
                        </label>
                    </motion.div>
                    <br /><br />



                    <Box>
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie12")}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <div style={{ borderLeft: "10px rgba(255, 164, 18, .5) solid", padding: "3%" }}>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box padding={2} >
                                <center><img style={{ borderRadius: "100%" }} width={"100%"} src={require("../Database/Files/APropos/Historiques/Imeuble1.png")} alt="" /></center>
                            </Box>
                            <Box>
                                <motion.div
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true, amount: 0.8 }}
                                    variants={scroll}
                                    className='produitsEtServices'
                                >
                                    <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                        {t("HISTORIQUE.texte.partie13")}
                                    </label>
                                </motion.div>
                                <br /><br />
                                <motion.div
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true, amount: 0.8 }}
                                    variants={scroll}
                                    className='produitsEtServices'
                                >
                                    <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                        {t("HISTORIQUE.texte.partie14")}
                                    </label>
                                </motion.div>
                                <br /><br />
                                <motion.div
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true, amount: 0.8 }}
                                    variants={scroll}
                                    className='produitsEtServices'
                                >
                                    <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                        {t("HISTORIQUE.texte.partie15")}
                                    </label>
                                </motion.div>
                            </Box>
                        </Box>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie16")}
                            </label>
                        </motion.div>
                        <br /><br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie17")}
                            </label>
                        </motion.div>
                        <br /><br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie18")}
                            </label>
                        </motion.div>
                        <br /><br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("HISTORIQUE.texte.partie19")}
                            </label>
                        </motion.div>
                        <br />
                    </div><br />
                    <img width={"50%"} src={illution_4} alt="" />
                    <br /><br />
                    <center>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <StepperAgence />
                            <hr style={{ visibility: "hidden" }} />
                            <StepperTempsFort />
                        </Box>
                    </center>
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br />


            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default Historique