import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import illution_4 from "../Fichiers/illution_4.svg";
import { Avatar, Box, Container, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { useNavigate } from 'react-router-dom';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import { TextFields } from '@mui/icons-material';
import Files from "../Database/Files.json";

interface Props { }

function Statut(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    function postules() {
        navigate("/postule")
    }


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={t("PRODUITS_SERVICES.titre46")} lien="Label.Titre.titre24" />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <Box display={{ xs: 'block', sm: 'none' }}>
                <br /><br />
            </Box>

            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <Box gap={5} display={{ sx: "none", sm: "contents" }}>
                    <img style={{ width: "5%" }} draggable={false} src={EllipseGauche} alt="" />
                </Box>
                <Container maxWidth='lg'>

                    <Box gap={5} display={{ sx: "block", sm: "flex" }}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <img style={{ borderTopLeftRadius: "30%", borderTopRightRadius: "10%", borderBottomLeftRadius: "10%", borderBottomRightRadius: "30%" }} width={300} height={300} src={require("../Database/Files/Contactez nous/1 9466812.png")} alt="" />
                                </motion.div>
                            </Box>
                        </motion.div>
                        <Box >
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <img width={"80%"} src={illution_4} alt="" /><br /><br />
                                <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("STATUT.texte.partie1").toUpperCase()}</label>
                                <br /><br />
                                <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                    {t("STATUT.texte.partie2")}
                                </label>
                                <br />
                                <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                    {t("STATUT.texte.partie3")}
                                </label>
                                <br /><br />
                                <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                    {t("STATUT.texte.partie4")}
                                </label>
                                <br /><br />
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("STATUT.texte.partie5").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                            </motion.div>
                            <br /><br /><br />
                        </Box>
                    </Box>

                </Container>
                <Box gap={5} display={{ sx: "none", sm: "contents" }}>
                    <img style={{ width: "5%" }} draggable={false} src={EllipseDroite} alt="" />
                </Box>
            </Box>
            <br /><br />
            <br /><br /><br />

            <Footer />
        </div>
    )
}
export default Statut