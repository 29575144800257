import { Carousel, Typography } from 'antd'
import { Variants, motion } from 'framer-motion'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import illution_4 from "../Fichiers/illution_4.svg";
import axios from 'axios'
import Data from "../Data/.json";
import { useEffect, useState } from 'react'
import env from 'react-dotenv'
import OffreM from '../Models/Offre'
import Offre from './Offre'

interface Props { }

function NosOffres(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const [datas, setDatas] = useState<OffreM[]>([])
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(true);

    const settings = {
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }
    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    
    if (pageLoaded) {
        setPageLoaded(false)
        LoadDatas()
    }


    function LoadDatas() {
        setLoading(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: env.API_BASE_URL + Data.API_LINKs.AllReadOffres,
            headers: {
                'elmt1': 'AllPost',
                'Content-Type': 'application/json, application/json',
                'Authorization': 'Bearer ' + env.API_TOKEN,
            }
        };

        axios.request(config)
            .then((response) => {
                setDatas(response.data.Result.Table as OffreM[])

                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            });

    }

    return (
        <div>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            ><motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                    <Box textAlign={"justify"} >
                        <h1>{t("TITRE.NosOffres")}</h1>
                        <img width={"100%"} draggable={false} src={illution_4} alt="" />
                        <br /><br />
                        <label className='textgray'>{t("SOUS_TITRE.NosOffres")}</label>
                        <br /><br />
                    </Box>
                </motion.div>
            </motion.div>
            <br />

            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
            </motion.div>
            <br />
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <center>
                    {loading ?
                        <CircularProgress color='success' />
                        :
                        datas.length==0?
                        <Typography>{t("Label.Titre.titre45")}</Typography>
                        :
                        <div id='offres'>
                            <Box display={{ xs: "none", sm: "block" }}>

                                <Carousel slidesToShow={datas.length >= 4 ? 4: datas.length} dots={false} arrows {...settings} dotPosition='bottom'>
                                    {datas.map((o: OffreM) => (
                                        <Offre offre={o} />
                                    ))}
                                </Carousel>
                            </Box>
                            <Box display={{ xs: "block", sm: "none" }}>

                                <Carousel slidesToShow={1} dots={false} arrows {...settings} dotPosition='bottom'>
                                    {datas.map((o: OffreM) => (
                                        <Offre offre={o} />
                                    ))}
                                </Carousel>
                            </Box>
                        </div>
                    }
                </center>

            </motion.div>
        </div >
    )
}

export default NosOffres
