import { LinkedIn, Facebook, Mail, YouTube, Twitter } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import logo from "../Fichiers/Logo.png"
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import env from 'react-dotenv';
import { useForm } from 'react-hook-form';
import Data from '../Data/.json';
import ReseauxSociaux from './ReseauxSociaux';



interface Props { }

function Footer(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const [load, setLoad] = useState(false)
    const { register, handleSubmit, reset } = useForm()

    function suscribe(data: any) {
        setLoad(true)
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: env.API_BASE_URL + Data.API_LINKs.UserSendEmails,
            headers: {
                'Content-Type': 'application/json, application/json',
                'Authorization': 'Bearer ' + env.API_TOKEN,
            },
            data: data["email"]
        };
        axios.request(config)
            .then((response) => {
                Swal.fire({
                    title: response.data.Result.Table[0].Message,
                    icon: (response.data.Result.Table[0].Status == 200) ? 'success' : "info"
                })
                setLoad(false)
                if (response.data.Result.Table[0].Status == 200) {
                    reset()
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: error,
                    icon: 'error'
                })
                setLoad(false)
            });

    }

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, link: string) => {
        const anchor =
            (
                (event.target as HTMLDivElement).ownerDocument || document
            ).querySelector(link.includes("#") ? ("#" + link.split("#")[1]) : "#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <div>
            <footer>
                <Box className='footer' >
                    <img src={logo} width={100} alt="MUPECI" />
                    <hr />
                    <Box display={{ xs: 'block', md: 'flex' }} gap={5}>
                        <Box gap={1} sx={{ flexGrow: 1, display: 'block' }}>
                            <label htmlFor="" className='titreGras'>{t("TITRE.AProposDeNous")}</label><br />
                            <Box width={"100%"}><br />
                                <label htmlFor="" className='sousTitre'>{t("SOUS_TITRE.AProposDeNous")}</label>
                            </Box>
                        </Box>
                        <hr style={{ opacity: "0%" }} />
                        <Box minWidth={200} gap={1} sx={{ flexGrow: 1, display: 'block' }}>
                            <label htmlFor="" className='titreGras'>{t("TITRE.TermeEtConditions")}</label><br />
                            <Box onClick={(e) => { handleClick(e, "/politiqueDeconfidentialite") }} className='sousTitre' width={"100%"}><br />
                                <Link className='textgray' to="/politiqueDeconfidentialite">{t("Label.politiqueConfidentialite")}</Link>
                            </Box>
                            <Box onClick={(e) => { handleClick(e, "/politiqueDeconfidentialite") }} className='sousTitre' width={"100%"}><br />
                                <Link className='textgray' to="/termesEtConditions">{t("TITRE.TermeEtConditions").toLowerCase()}</Link>
                            </Box>
                        </Box>
                        <hr style={{ opacity: "0%" }} />
                        <Box gap={1} sx={{ flexGrow: 1, display: 'block' }}>
                            <label htmlFor="" className='titreGras'>{t("TITRE.NewsLetter")}</label><br />
                            <Box width={"100%"}><br />
                                <form onSubmit={handleSubmit(suscribe)}>
                                    <label htmlFor="" className='sousTitre'>{t("SOUS_TITRE.NewsLetter")}</label>
                                    <TextField disabled={load} variant='filled' type='email' label={'Adresse Mail'} required {...register("email")} color='success' fullWidth placeholder='Votre mail' size='small' /><br /><br />
                                    <Button type='submit' disabled={load} endIcon={load ? <CircularProgress size={10} color='inherit' /> : ""} color='success' style={{ boxShadow: "none" }} fullWidth size='small' variant='contained'>{t("Label.Titre.titre55")}</Button>
                                </form>
                            </Box>
                        </Box>
                        <hr style={{ opacity: "0%" }} />
                        <Box minWidth={150} sx={{ display: 'block' }}>
                            <label htmlFor="" className='titreGras'>{t("TITRE.SuivezNous")}</label><br /><br />
                            <Box gap={1} display={"flex"}>
                                <ReseauxSociaux />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </footer>
            <Box className='sousTitre' textAlign={"center"}>
                {t("copyright")}
            </Box>
            <br />
        </div>
    )
}

export default Footer
