import { Box, Container } from '@mui/material'
import illution_4 from "../Fichiers/illution_4.svg";
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import texte_agenceRegions from "../Fichiers/texte_agenceRegions.svg"
import { Variants, motion } from 'framer-motion';
import Map from './Map';
import { useState } from 'react';
import CoordonneMap from '../Models/CoordonneMap';
import Agences from "../Database/Agences.json";
import Regions from './Regions';
import { useTranslation } from 'react-i18next';

interface Props { }

function NosAgences(props: Props) {

    const [regionSelect, setRegionSelect] = useState<CoordonneMap>(Agences[0].coordonnees)
    const { t, i18n } = useTranslation();



    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    function centrer(coordonnees: CoordonneMap) {
        setRegionSelect(coordonnees)
    }



    return (

        <Box >
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
            >
                <Container maxWidth="lg">
                    <Box textAlign={"justify"} >
                        <h1>{t("TITRE.NosAgence")}</h1>
                        <img width={"100%"} draggable={false} src={illution_4} alt="" />
                        <br /><br />
                        <label className='textgray' htmlFor="">{t("SOUS_TITRE.NosAgence")}</label>
                    </Box>

                    <br /><br />
                </Container>
            </motion.div>
            <Box display={"flex"} className='agence' >
                <Box display={{ xs: 'none', sm: 'contents' }}>
                    <img style={{ width: "5%" }} draggable={false} src={EllipseGauche} alt="" />
                </Box>
                <Container maxWidth='lg'>
                    <Box padding={3} display={{ xs: 'block', sm: 'flex' }}>
                        <Box>
                            <label className='textgray' htmlFor="">{t("Label.NombreAgenceRegion")}</label><br />
                            <img  width={"100%"} draggable={false} src={illution_4} alt="" /><br />
                            <Regions centralize={centrer} />
                        </Box>
                        <hr style={{ visibility: "hidden" }} />
                        <Box className='map' width={{ xs: '100%', sm: '80%' }} >
                            <br /><br />
                            <Map center={regionSelect} />
                        </Box>
                    </Box>
                </Container>
                <Box display={{ xs: 'none', sm: 'contents' }}>
                    <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
                </Box>
            </Box>
        </Box>
    )
}

export default NosAgences
