import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import SousPub from '../Components/SousPub';
import { Box, Container } from '@mui/material';
import NosAgences from '../Components/NosAgences';
import { motion } from "framer-motion"
import NosProduitsEtServices from '../Components/NosProduitsEtServices';
import Assistant from '../Components/Assistant';
import NosActualitesEtEvenements from '../Components/NosActualitesEtEvenements';
import NosStatistique from '../Components/NosStatistique';
import NosPartenaire from '../Components/NosPartenaire';
import ProduitEtServiceList from "../Database/ProduitEtServiceList.json";
import Files from "../Database/Files.json";

interface Props { }

function Home(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <Header />
            <motion.div
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -100, opacity: 0 }}
            >
                <Container maxWidth='lg'>
                    <Box className='AllSousPub' gap={5} display={{ xs: 'block', sm: 'flex' }}>
                        {ProduitEtServiceList.map((p) => (
                            p.subPub?
                            <>
                                <SousPub title={p.titre} image={require("../Database/Files/ProduitsEtServices/"+p.image)} minidescription={p.description} lien={p.link} />
                                <br />
                            </>
                            : ""
                        ))}
                    </Box><br />
                </Container>
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
            >
                <center>
                    <NosAgences />
                </center>
            </motion.div>
            <br />

            <Container maxWidth='lg'>
                <NosProduitsEtServices />
            </Container>
            <br /><br />

            <Box className='assistanceBox'>
                <Container maxWidth='lg'>
                    <Assistant />
                </Container>
            </Box>
            <br />


            <Container maxWidth='lg'>
                <NosStatistique />
            </Container>
            <br />

            <Box className='PartenaireBox'>
                <Container maxWidth='lg'>
                    <NosActualitesEtEvenements />
                </Container>
            </Box>

            <br />


            <Box >
                <Container maxWidth='lg'>
                    <NosPartenaire />
                </Container>
            </Box>
            <br /><br />
            <br /><br />



            <Footer />
        </div>
    )
}
export default Home