import DrawerAppBar from './DrawerAppBar'
import Publicite from './Publicite'
import Menus from './Menus'
import { Carousel } from 'antd'
import { motion } from 'framer-motion'
import PubList from "../Database/ProduitEtServiceList.json";

interface Props { }
function Header(props: Props) {
    const { } = props
    return (
        <div>
            <DrawerAppBar /><br />
            <Menus />
            <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -500, opacity: 0 }}
            >
                <Carousel vertical={false}  autoplay dotPosition='top'>
                    {PubList.map((p) => (
                        p.pub? <Publicite produitServices={p}  />: ""
                    ))}
                </Carousel>
            </motion.div>

        </div>
    )
}

export default Header
