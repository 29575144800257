import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import ListeMenu from "../Database/ListeMenu.json";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const MenuMobile: React.FC = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    const onClick: MenuProps['onClick'] = (e) => {
        navigate(e.key.split("|")[0])
        const anchor =
            document.querySelector(e.key.split("|")[0].includes("#") ? ("#" + e.key.split("|")[0].split("#")[1]) : "#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };
    const [items, setItems] = useState<MenuProps['items']>([])
    const [load, setLoad] = useState(true)

    if (load) {
        setLoad(false)
        const menu: MenuProps['items'] = []

        ListeMenu.map((m, index) => {
            if (m.submenu.length == 0) {
                menu.push(
                    getItem(t(m.title), m.url+"|"+index),
                    { type: 'divider' },
                )
            }
            else {
                var sous_m: any = []

                m.submenu.map((sm, sm_index) => {
                    if (sm.elements.length == 0) {
                        sous_m.push(
                            getItem(t(sm.title), sm.url+"|"+index+sm_index)
                        )
                    }
                    else {
                        var sous_sous_m: any = []
                        sm.elements.map(((ssm, ssm_index) => {
                            sous_sous_m.push(
                                getItem(t(ssm.title), ssm.url+"|"+index+sm_index+ssm_index)
                            )
                        }))

                        sous_m.push(
                            getItem(t(sm.title), sm.url+"|"+index+sm_index, null, sous_sous_m)
                        )

                    }
                })

                menu.push(
                    getItem(t(m.title), m.url+"|"+index, null, sous_m),

                    { type: 'divider' },
                )
                setItems(menu)
            }
        })
    }

    return (
        <Menu
            onClick={onClick}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
        />
    );
};

export default MenuMobile;