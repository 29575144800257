import { Box } from '@mui/material'
import React from 'react'
import { Variants, motion } from "framer-motion";
import illution_4 from "../Fichiers/illution_4.svg";
import { useTranslation } from 'react-i18next';
import Files from "../Database/Files.json";

interface Props { }

function NousRejoindre(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();


    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    return (
        <Box display={"flex"}>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <Box>
                    <motion.div
                        whileHover={{ scale: 1, rotate: 1 }}
                        whileTap={{
                            scale: 1,
                            rotate: -1,
                        }}
                    >
                        <img style={{ borderTopLeftRadius: "30%", borderTopRightRadius: "10%", borderBottomLeftRadius: "10%", borderBottomRightRadius: "30%" }} width={'90%'} src={require("../Database/Files/Nous regoindre/1 9466813.png")} alt="" />
                    </motion.div>
                </Box>
            </motion.div>
            <Box >
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    variants={scroll}
                    className='produitsEtServices'
                >
                    <img width={"80%"} src={illution_4} alt="" /><br /><br />
                    <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("TITRE.NousRejoindre")}</label>
                    <br /><br />
                    <label className='textgray' style={{ width: "30%" }} htmlFor="">
                    {t("SOUS_TITRE.NousRejoindre")}
                    </label>
                </motion.div>
                <br /><br /><br />
            </Box>
        </Box>
    )
}

export default NousRejoindre
