import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import illution_4 from "../Fichiers/illution_4.svg";
import { Box, Container } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { useNavigate } from 'react-router-dom';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import Files from "../Database/Files.json";

interface Props { }

function MissionEtVision(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    function postules() {
        navigate("/postule")
    }


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={t("PRODUITS_SERVICES.titre6")} lien="Label.Titre.titre19" />
            <br /><br /><br /><br /><br /><br /><br /><br />
            <Box display={{ xs: 'block', sm: 'none' }}>
                <br /><br />
            </Box>

            <Box display={"flex"} textAlign={"justify"}>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />
                <Container maxWidth='lg'>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center> <img style={{ borderRadius: "2%" }} width={250} src={require("../Database/Files/APropos/On avance ensemble/close-up-team-hand-shake 1.png")} alt="" /></center>
                                </motion.div>
                            </Box>
                        </motion.div>
                        <Box >
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                                style={{ padding: "2%" }}
                            >
                                <img width={"80%"} src={illution_4} alt="" /><br />
                                <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("Label.Titre.titre65").toUpperCase()}</label>
                                <br /><br />
                                <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                    {t("MISSION_VISION.text.Mission")}
                                </label>
                            </motion.div>

                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <Container maxWidth='lg'>
                                    <Box className='boxCS'>
                                        <img width={"80%"} src={illution_4} alt="" /><br />
                                        <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("Label.Titre.titre66").toUpperCase()}</label>
                                        <br />
                                        <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                            {t("MISSION_VISION.text.Vision")}
                                        </label>
                                    </Box>
                                </Container>

                            </motion.div>
                        </Box>
                    </Box>


                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br />
            <br /><br /><br />

            <Footer />
        </div>
    )
}
export default MissionEtVision