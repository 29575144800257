import React from 'react'
import File from "../../Fichiers/404.svg";
import DrawerAppBar from '../../Components/DrawerAppBar';
import Menus from '../../Components/Menus';
import { Avatar, Box, Button, Container, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import Footer from '../../Components/Footer';
import EllipseGauche from "../../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../../Fichiers/Ellipse 201.svg"
import { useNavigate } from 'react-router-dom';
import TitrePage from '../../Components/TitrePage';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";

interface Props { }

function Categorisation(props: Props) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'PRODUITS_SERVICES.titre31'} lien={'Label.Titre.titre7'} />
            <br /><br /><br /><br /><br /><br /><br /><br /><br />
            <Box display={"flex"}>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />
                <hr style={{ opacity: 0 }} />
                <center>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: "10px" }}>

                        {t("PRODUIT_PRET_PAR_CATEGORIE.titres").split('|').map((c, index) => (
                            <motion.div
                                
                                whileHover={{
                                    scale: 1.2,
                                    transition: { duration: 1 },
                                }}
                                whileTap={{ scale: 0.9 }}
                            >


                                <ListItem className='div_pret' style={{ backgroundColor: "#FFF4E1", borderRadius: "20px" }} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar variant='rounded' alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={c}
                                        secondary={
                                            t("PRODUIT_PRET_PAR_CATEGORIE.elements").split('|').map((e, index_e) => (

                                                <>
                                                    {
                                                        (index == index_e) ?
                                                            <React.Fragment>
                                                                {e.split("&").map((ee) => (
                                                                    ee.length != 0?
                                                                    <>
                                                                        {"- " + ee}
                                                                        <br />
                                                                    </>
                                                                    :
                                                                    ""

                                                                ))}
                                                            </React.Fragment>
                                                            :
                                                            ""
                                                    }
                                                </>
                                            ))
                                        }

                                    />
                                </ListItem>
                                <br />
                            </motion.div>
                        ))}
                    </List>
                </center>
                <hr style={{ opacity: 0 }} />
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br /><br />
            <Footer />
        </div>
    )
}

export default Categorisation
