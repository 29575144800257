import { Box, Container } from '@mui/material'
import { Variants, motion } from "framer-motion";
import illution_4 from "../Fichiers/illution_4.svg";
import { useTranslation } from 'react-i18next';
import Files from "../Database/Files.json";

interface Props { }

function Assistant(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();


    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    return (
        <Box display={"flex"}>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <Box>
                    <motion.div
                        whileHover={{ scale: 1, rotate: 1 }}
                        whileTap={{
                            scale: 1,
                            rotate: -1,
                        }}
                    >
                        <img style={{ borderTopLeftRadius: "30%", borderTopRightRadius: "10%", borderBottomLeftRadius: "10%", borderBottomRightRadius: "30%" }} width={'70%'} src={require("../Database/Files/Contactez nous/Assistante 1.png")} alt="" />
                    </motion.div>
                </Box>
            </motion.div>
            <Box >
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    variants={scroll}
                >
                    <Container>
                        <Box textAlign={"justify"} >
                            <h1>{t("TITRE.ServiceAssitance")}</h1>
                            <img width={"100%"} draggable={false} src={illution_4} alt="" />
                            <br /><br />
                            <label className='textgray' htmlFor="">{t("SOUS_TITRE.ServiceAssitance")}</label>

                            <br /><br />

                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <img width={"20%"} draggable={false} src={illution_4} alt="" />
                            </motion.div>
                        </Box>
                    </Container>
                </motion.div>
            </Box>
        </Box>
    )
}

export default Assistant
