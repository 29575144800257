import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Agences from "../Database/Agences.json";
import APropos from "../Database/APropos.json";
import { useTranslation } from 'react-i18next';


export default function StepperTempsFort() {
    const [activeStep, setActiveStep] = React.useState(0);
    const { t, i18n } = useTranslation();

    const handleNext = (step: number) => {
        setActiveStep(step);
    };
    return (
        <Box padding={2} borderLeft={1}>
            <Typography textAlign={"left"} variant='h6'>{t("HISTORIQUE.texte.partie21")}</Typography>
            <Box height={400} overflow={"auto"} padding={3}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {((t("HISTORIQUE.texte.TABLEAU_HIST")).split("|")).map((tab, index) => (
                        <Step key={index}>
                            <StepLabel>
                                <Button variant='contained' style={{ boxShadow: "none", backgroundColor: "rgba(255, 164, 18, .1)", color: "black" }} fullWidth onClick={() => { handleNext(index) }}>
                                    <Box>
                                        <Typography fontWeight={"bold"}>{tab.split("&")[1]}</Typography>
                                        <Typography >{tab.split("&")[0].substring(0, 50) + (tab.split("&")[0].length > tab.split("&")[0].substring(0, 50).length ? "..." : "")}</Typography>
                                    </Box>
                                </Button>
                                <hr color='orange' />
                            </StepLabel>
                            <StepContent>
                                <Box style={{ padding: "3%" }} textAlign={'left'}>
                                    <Typography fontWeight={"bold"}>{tab.split("&")[2]}</Typography>
                                    <hr />
                                    <label htmlFor="">{tab.split("&")[0]}</label>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Box>
    );
}
