import { Box } from '@mui/material'
import traits from "../Fichiers/traits.png";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ProduitServices from '../Models/ProduitServices';
import Files from "../Database/Files.json";
import { useTranslation } from 'react-i18next';

function Publicite(props: { produitServices : ProduitServices}) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    return (
        <Box display={{xs: "none", sm: "flex"}} height={500} className={"pub"}>
            <Box className="pub-image">
                {props.produitServices.image? <img width={"100%"} src={require("../Database/Files/ProduitsEtServices/"+props.produitServices.image)} alt="" /> : ""}
            </Box>
            <Box className="pub-text">
            <label htmlFor="">{(t(props.produitServices.titre)).toUpperCase()}</label>
            <hr style={{opacity: "10%"}} />
                <label style={{ fontWeight: "lighter"}} htmlFor="">{t(props.produitServices.description)}</label><br />

                <motion.button
                    whileHover={{
                        scale: 1.2,
                        transition: { duration: 1 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    onClick={()=>{navigate(props.produitServices.link)}}
                    className='btn'>{t("Bouton.EnSavoirPlus")}</motion.button>
            </Box>
            <img width={"20%"} draggable={false} className='traits' src={traits} alt="" />
        </Box>
    )
}

export default Publicite
