import { Box, Container } from '@mui/material'
import { Variants, motion } from 'framer-motion';
import React from 'react'
import illution_6 from "../Fichiers/illution_6.svg";
import { useTranslation } from 'react-i18next';
import illution_4 from "../Fichiers/illution_4.svg";

interface Props { }

function NosStatistique(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();

    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={scroll}
            className='produitsEtServices'
        >
            <Box>
                <center>

                    <Box textAlign={"justify"} >
                        <h1>{t("TITRE.NosStatistique")}</h1>
                        <img width={"100%"} draggable={false} src={illution_4} alt="" />
                        <br /><br />
                        <center><h2 className='textgray'>{t("SOUS_TITRE.NosStatistique").toUpperCase()}</h2></center>
                        <br /><br />
                    </Box>

                    <Box overflow={"auto"} gap={2} display={"flex"}>
                        <motion.div
                            whileHover={{ scale: 1.2, rotate: 5 }}
                            whileTap={{
                                scale: 0.8,
                                rotate: -90,
                                borderRadius: "100%"
                            }}
                        >
                            <center style={{ color: "white", backgroundImage: "linear-gradient(rgb(255, 107, 214), rgb(255, 29, 192))", width: "200px", padding: "5px", borderRadius: "50px" }}>
                                <label style={{ fontSize: "xx-large", fontWeight: "bolder" }} htmlFor="">{t("STATISTIQUES.partie1").split("&")[0]}</label><br />
                                <label htmlFor="">{t("STATISTIQUES.partie1").split("&")[1]}</label>
                            </center>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.2, rotate: 5 }}
                            whileTap={{
                                scale: 0.8,
                                rotate: -90,
                                borderRadius: "100%"
                            }}
                        >
                            <center style={{ color: "white", backgroundImage: "linear-gradient(rgb(94, 255, 228), rgb(9, 196, 165))", width: "200px", padding: "5px", borderRadius: "50px" }}>
                                <label style={{ fontSize: "xx-large", fontWeight: "bolder" }} htmlFor="">{t("STATISTIQUES.partie2").split("&")[0]}</label><br />
                                <label htmlFor="">{t("STATISTIQUES.partie2").split("&")[1]}</label>
                            </center>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.2, rotate: 5 }}
                            whileTap={{
                                scale: 0.8,
                                rotate: -90,
                                borderRadius: "100%"
                            }}
                        >
                            <center style={{ color: "white", backgroundImage: "linear-gradient(rgb(255, 164, 18), rgb(255, 217, 156))", width: "200px", padding: "5px", borderRadius: "50px" }}>
                                <label style={{ fontSize: "xx-large", fontWeight: "bolder" }} htmlFor="">{t("STATISTIQUES.partie3").split("&")[0]}</label><br />
                                <label htmlFor="">{t("STATISTIQUES.partie3").split("&")[1]}</label>
                            </center>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.2, rotate: 5 }}
                            whileTap={{
                                scale: 0.8,
                                rotate: -90,
                                borderRadius: "100%"
                            }}
                        >
                            <center style={{ color: "white", backgroundImage: "linear-gradient(rgb(0, 0, 0), rgb(96, 96, 96))", width: "200px", padding: "5px", borderRadius: "50px" }}>
                                <label style={{ fontSize: "xx-large", fontWeight: "bolder" }} htmlFor="">{t("STATISTIQUES.partie4").split("&")[0]}</label><br />
                                <label htmlFor="">{t("STATISTIQUES.partie4").split("&")[1]}</label>
                            </center>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.2, rotate: 5 }}
                            whileTap={{
                                scale: 0.8,
                                rotate: -90,
                                borderRadius: "100%"
                            }}
                        >
                            <center style={{ color: "white", backgroundImage: "linear-gradient(rgb(10, 20, 5), rgb(10, 20, 105))", width: "200px", padding: "5px", borderRadius: "50px" }}>
                                <label style={{ fontSize: "xx-large", fontWeight: "bolder" }} htmlFor="">{t("STATISTIQUES.partie5").split("&")[0]}</label><br />
                                <label htmlFor="">{t("STATISTIQUES.partie5").split("&")[1]}</label>
                            </center>
                        </motion.div>
                    </Box>

                </center>
            </Box>


        </motion.div>
    )
}

export default NosStatistique
