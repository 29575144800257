import React from 'react'
import logo from "../Fichiers/Logo.png";

interface Props { }

function Load(props: Props) {
    const { } = props

    return (
        <div className='loading'>
            <img width={250} className='img-loading'src={logo} alt="MUPECI"  />
        </div>
    )
}

export default Load
