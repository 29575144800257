import { Box } from '@mui/material'
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';


interface SousPub {
    title: string,
    image: string,
    minidescription: string,
    lien: string,
}

function SousPub(props: SousPub) {
    const { t, i18n } = useTranslation();

    return (
        <Box className='sousPub' display={{xs: "block", sm: "flex"}}>
            <Box display={{xs: "none", sm: "flex"}}>
                <img style={{borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px"}} width={"250"} height={"100%"} src={props.image} alt="" />
            </Box>
            <Box display={{xs: "block", sm: "none"}}>
                <img style={{borderTopLeftRadius: "10px", borderBottomLeftRadius: "50px"}} width={"100%"} height={"100%"} src={props.image} alt="" />
            </Box>
            <Box>
                <Box margin={2} color={"whites"}>
                    <h3>{(t(props.title)).toUpperCase()}</h3>
                    <hr />
                    {t(props.minidescription)}
                </Box>
                <Box margin={2} >
                    <motion.button

                        whileHover={{
                            scale: 1.2,
                            transition: { duration: 1 },
                        }}
                        whileTap={{ scale: 0.9 }}
                        className='btn'>{t("Bouton.EnSavoirPlus")}
                    </motion.button>
                    <br /><br />
                </Box>
            </Box>
        </Box>
    )
}

export default SousPub
