import { Variants, motion } from "framer-motion"
import { Carousel, Tooltip } from 'antd';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import illution_4 from "../Fichiers/illution_4.svg";
import { useTranslation } from 'react-i18next';
import ListePartenaire from "../Database/ListePartenaire.json";

interface Props { }

function NosPartenaire(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();

    const settings = {
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }
    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    return (
        <div>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    variants={scroll}
                    className='produitsEtServices'
                >
                    <Box textAlign={"justify"} >
                        <h1>{t("TITRE.NosPartenaires")}</h1>
                        <img width={"100%"} draggable={false} src={illution_4} alt="" />
                        <br /><br />
                        <label className='textgray'>{t("SOUS_TITRE.NosPartenaires")}</label>
                        <br /><br />
                    </Box>
                </motion.div>
            </motion.div>
            <br />
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
            </motion.div>
            <br />
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >

                <Box display={{ xs: "none", sm: "block" }}>

                    <Carousel slidesToShow={5} dots={false} arrows {...settings} autoplay dotPosition='bottom'>
                        {
                            ListePartenaire.partenaires.map((p) => (
                                <Tooltip title={p.name}>
                                    <br />
                                    <img width={150} style={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)", borderRadius: "15px", borderBottom: "3px #0D6638 solid" }} src={require("../Database/Files/Partenaires/" + p.image)} />
                                    <h6 className='textgray'>{p.name}</h6>
                                    <br />
                                </Tooltip>
                            ))
                        }
                    </Carousel>
                </Box>
                <Box display={{ xs: "block", sm: "none" }}>

                    <center>
                        <Carousel slidesToShow={2} dots={false} arrows {...settings} autoplay dotPosition='bottom'>
                            {
                                ListePartenaire.partenaires.map((p) => (
                                    <Tooltip title={p.name}>
                                        <br />
                                        <img width={"80%"} style={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)", borderRadius: "15px", borderBottom: "3px #0D6638 solid" }} src={require("../Database/Files/Partenaires/" + p.image)} />
                                        <h6 className='textgray'>{p.name}</h6>
                                        <br />
                                    </Tooltip>
                                ))
                            }
                        </Carousel>
                    </center>
                </Box>
            </motion.div>
        </div>
    )
}

export default NosPartenaire