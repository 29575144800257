import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import { Empty } from 'antd';
import { ArrowRight, ArrowRightRounded, Close, CloseRounded, KeyOff, Search, WebRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ListeMenuSearchFr from "../Database/ListeMenuSearchFr.json";
import ListeMenuSearchEn from "../Database/ListeMenuSearchEn.json";
import { Link } from 'react-router-dom';

export default function SearchPage() {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const { t, i18n } = useTranslation();
    const [data, setData] = React.useState(i18n.language == "en"? ListeMenuSearchEn: ListeMenuSearchFr)

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setData(i18n.language == "en"? ListeMenuSearchEn: ListeMenuSearchFr)
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    function handleChange(e : any) {
        setData((i18n.language == "en"? ListeMenuSearchEn: ListeMenuSearchFr).filter((search)=>search.title.toUpperCase().includes(e.target.value.toUpperCase())  || search.description.toUpperCase().includes(e.target.value.toUpperCase()) || search.dictionnaire.toUpperCase().includes(e.target.value.toUpperCase()) ))
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen('paper')} size='small' style={{ border: "1px solid", left: "5%" }} color='info'><Search /></IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">
                    <Box gap={2} display={"flex"}>
                        <Search color='success' fontSize='large' />
                        <TextField focused type='search' onChange={handleChange} color='success' size='medium' placeholder={t("placeholder")} fullWidth variant='standard' />
                        <Button startIcon={<CloseRounded />} onClick={handleClose} style={{width: "50px", borderRadius: "5px", backgroundColor: "lightgray", color: "gray"}}>esc</Button>
                    </Box>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {
                            data.length != 0?
                            <List>
                            {data.map((tt) => (
                                <>
                                    <Link className='lien' to={tt.url}>
                                        <ListItem className='list' alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp"><WebRounded /></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={t(tt.title)}
                                                secondary={
                                                    <React.Fragment>
                                                        {tt.description}
                                                    </React.Fragment>
                                                }
                                            />
                                            <ArrowRightRounded style={{marginTop: "15px"}} />
                                        </ListItem><br />
                                    </Link>
                                </>
                            ))}
                        </List>
                        :
                        <Empty />
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size='small' color='inherit' fullWidth >{t("copyright")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}