import Footer from '../../Components/Footer'
import { useTranslation } from 'react-i18next'
import Assistante from "../../Fichiers/Assistante.jpeg";
import { Avatar, Box, Button, CircularProgress, Container, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../../Components/DrawerAppBar';
import Menus from '../../Components/Menus';
import TitrePage from '../../Components/TitrePage';
import { Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, Facebook, Home, Instagram, LinkedIn, LocationOn, Mail, PhoneAndroid, TextDecrease, TextFields } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import UploadFile from '../../Components/UploadFile';
import EllipseGauche from "../../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../../Fichiers/Ellipse 201.svg"
import illution_4 from "../../Fichiers/illution_4.svg";
import nousRejoindre from "../../Fichiers/nousRejoindre.jpg";
import APropos from "../../Database/APropos.json";
import { Carousel } from 'antd';
import StepperAgence from '../../Components/StepperAgence';
import StepperTempsFort from '../../Components/StepperTempsFort';

interface Props { }

function CompteParticulier(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'PRODUITS_SERVICES.titre17'} lien={'Label.Titre.titre3'} />
            <br /><br /><br /><br /><br /><br /><br /><br />



            <Box display={{ xs: 'block', sm: 'flex' }} lineHeight={1.6} textAlign={"justify"}>
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>

                    <Box display={{ xs: 'block', sm: 'flex' }} >

                        <Box textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <br />
                                <img width={"80%"} src={illution_4} alt="" /><br /><br />

                            </motion.div>
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("COMPTES.particulier.texte.partie1")}</label>
                            <br /><br />
                            <label className='textgray' style={{ fontSize: 'large', fontWeight: "bold" }} htmlFor="">
                                {t("COMPTES.particulier.texte.partie2")}
                            </label>
                            <br /><br />

                        </Box>
                        <Box width={120} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center><br /><br />
                                        <Box>
                                            <div><img style={{ borderRadius: "10%" }} width={300} height={300} src={require('../../Database/Files/ProduitsEtServices/Comptes/focused-workaholic-young-businesswoman-working-company-financial-charts-presentation-late-night-meeting-office-room 1.png')} alt="" /></div>
                                        </Box>
                                    </center>
                                </motion.div>
                            </Box>
                        </motion.div>
                    </Box>
                    <br />
                    <img width={"80%"} src={illution_4} alt="" /><br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">
                            {t("COMPTES.particulier.texte.partie3")}
                        </label>
                    </motion.div>
                    <br /><br />


                    <Box display={{ xs: 'block', sm: 'flex' }}>

                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box gap={20}>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <img style={{ borderRadius: "10%" }} width={300} height={300} src={require('../../Database/Files/ProduitsEtServices/Comptes/image 7.png')} alt="" />
                                </motion.div>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <img style={{ borderRadius: "10%" }} width={300} height={300} src={require('../../Database/Files/ProduitsEtServices/Comptes/medium-shot-man-holding-vegetables 1.png')} alt="" />
                                </motion.div>
                            </Box>
                        </motion.div>
                        <Box width={100} />
                        <Box width={"100%"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' htmlFor="">
                                    {t("COMPTES.particulier.texte.partie4")}
                                </label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify", fontSize: "large", fontWeight: "bold" }} htmlFor="">
                                    {t("COMPTES.particulier.texte.partie5").toUpperCase()}
                                </label>
                                <br /><br />
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("COMPTES.particulier.texte.partie6").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                            </motion.div>

                        </Box>
                    </Box>
                    <br />
                    <Box>
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("COMPTES.particulier.texte.partie7").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify", fontWeight: "bold", fontSize: "large" }} htmlFor="">
                                    {t("COMPTES.particulier.texte.partie8")}
                                </label><br /><br />
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("COMPTES.particulier.texte.partie9").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <br />
                                <label className='textgray' htmlFor="">
                                    {t("COMPTES.particulier.texte.partie10")}
                                </label>
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={250} src={require('../../Database/Files/ProduitsEtServices/Comptes/image 7.png')} alt="" />
                            </motion.div>
                        </Box>
                    </Box>
                    <br /><br />








                    <Box>
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("COMPTES.particulier.texte.partie11").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                    {t("COMPTES.particulier.texte.partie12")}
                                </label>
                                <br /><br />
                                <label className='textgray' htmlFor="">
                                    {t("COMPTES.particulier.texte.partie13")}
                                </label>
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={250} src={require('../../Database/Files/ProduitsEtServices/Comptes/diversity-people-talk-international-conference-partnership 1.png')} alt="" />
                            </motion.div>
                        </Box>
                    </Box>
                    <br /><br />

                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>

                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={250} src={require('../../Database/Files/ProduitsEtServices/Comptes/dynamic-business-network-collaborative-office-team-global-sphere 1.png')} alt="" />
                            </motion.div>
                        </Box>
                        <Box>
                            
                    <Box>
                        <img width={"100%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("COMPTES.particulier.texte.partie14").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box><br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                    {t("COMPTES.particulier.texte.partie15")}
                                </label>
                            </motion.div>
                        </Box>
                    </Box>


                    <img width={"50%"} src={illution_4} alt="" />
                    <br /><br />
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br />


            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default CompteParticulier