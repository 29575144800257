import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import Assistante from "../Fichiers/Assistante.jpeg";
import { Box, Button, CircularProgress, Container, IconButton, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, Facebook, Instagram, LinkedIn, LocationOn, Mail, PhoneAndroid } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import UploadFile from '../Components/UploadFile';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import illution_4 from "../Fichiers/illution_4.svg";
import nousRejoindre from "../Fichiers/nousRejoindre.jpg";
import APropos from "../Database/APropos.json";
import { Carousel } from 'antd';
import NosAgences from '../Components/NosAgences';

interface Props { }

function Agences(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'PRODUITS_SERVICES.titre43'} lien={'Label.Titre.titre12'} />
            <br /><br /><br /><br /><br /><br /><br /><br /><br />
            

            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
            >
                <center>
                    <NosAgences />
                </center>
            </motion.div>
            <br /><br />

            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default Agences